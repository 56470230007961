import './Stats.css';
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import SettingStorage from '../classes/SettingsStorage';
import DataFetcher from '../classes/DataFetcher';
import Digital from 'react-activity/dist/Digital';
import "react-activity/dist/Digital.css";
import Legend from '../Scores/Legend';
import Utilities from '../classes/Utilities';
import { Tooltip } from 'chart.js';

const Stats = (props) => {    
    let filteredStyle;
    let allStyle;
    let statType = SettingStorage.getStorageValue("nhlcountries_statType", "filtered");
    let statUpdateId = SettingStorage.getStorageValue("nhlcountries_statUpdateId", "");
    let selectedCountry = SettingStorage.getCountry();
    const [statTypeValue, setValue] = React.useState(statType);
    const [isLoading, setIsLoading] = React.useState(false);    
    const { t } = useTranslation();

    const updateStyles = (id) =>
    {        
        filteredStyle = id === "filtered" ? "StatTypeTextSelected" : "StatTypeText";
        allStyle = id === "all" ? "StatTypeTextSelected" : "StatTypeText";
    }

    updateStyles(statType);
    
    const loadingContent = <div className="Progress"><h4>{t("home_updatingData")}</h4><Digital/><div className='ProgressSmallPrint'>{t("home_updatingDataNote")}</div></div>;

    const onStatTypeSelected = async (e) => {                
        let id = e.target.id;
        SettingStorage.setStorageValue("nhlcountries_statType", id);                
        setValue(id);  
        updateStyles(id);

        if (id === "all")
        {
            await loadData(id);
        }
    }

    const loadData = async (type) => {
        // load the data here
        if ((props.allPlayers === undefined || props.allPlayers.length === 0) && type === "all")
        {            
            try
            {
                setIsLoading(true);                    
                const data = await DataFetcher.fetchAllPlayerData();   
                statUpdateId = props.updateId;
                SettingStorage.setStorageValue("nhlcountries_statUpdateId", statUpdateId);                
                props.allPlayerDataChanged(data);                                                     
            }
            finally
            {
                setIsLoading(false);
            }            
        }        
        if ((props.allPlayers !== undefined || props.allPlayers.length > 0) && type === "all")
        {   
            try
            {
                if (statUpdateId !== props.updateId)
                {
                    setIsLoading(true);
                    const data = await DataFetcher.fetchAllPlayerData();                    
                    statUpdateId = props.updateId;
                    SettingStorage.setStorageValue("nhlcountries_statUpdateId", statUpdateId);                
                    props.allPlayerDataChanged(data);                                     
                }
            }
            finally
            {
                setIsLoading(false);
            }
        }      
      };

    useEffect(() => {
        loadData(statTypeValue);
      }, []);
    
    let skaterSortOrder = SettingStorage.getSkaterSortOrder();       
    let goalieSortOrder = SettingStorage.getGoalieSortOrder();    
    const [skaterSortOrderValue, setSkaterSortOrderValue] = React.useState(skaterSortOrder);    
    const [goalieSortOrderValue, setGoalieSortOrderValue] = React.useState(goalieSortOrder);        
        
    const handleSkaterOrderChange = (event) => {
        setSkaterSortOrderValue(event.target.value);        
        SettingStorage.setSkaterSortOrder(event.target.value);                
      };

    const handleGoalieOrderChange = (event) => {
        setGoalieSortOrderValue(event.target.value);        
        SettingStorage.setGoalieSortOrder(event.target.value);                
      };
      
    const handleSkaterOrderChangeHeader = (id) => {        
        let newOrderValue;
        if (id + "Asc" === skaterSortOrderValue)
        {
            newOrderValue = id + "Desc";
        }
        else if (id + "Desc" === skaterSortOrderValue)
        {
            newOrderValue = id + "Asc";
        }
        else{
            newOrderValue = id + "Desc";
        }
        setSkaterSortOrderValue(newOrderValue);        
        SettingStorage.setSkaterSortOrder(newOrderValue);                
      };

    const handleGoalieOrderChangeHeader = (id) => {        
        let newOrderValue;
        if (id + "Asc" === goalieSortOrderValue)
        {
            newOrderValue = id + "Desc";
        }
        else if (id + "Desc" === goalieSortOrderValue)
        {
            newOrderValue = id + "Asc";
        }
        else{
            newOrderValue = id + "Desc";
        }
        setGoalieSortOrderValue(newOrderValue);        
        SettingStorage.setGoalieSortOrder(newOrderValue);                
      };
    const headers = [];          
    headers.push({ name: "#", caption: "#", index: -1 });
    headers.push({ name: "name", caption: t("statColumn_name"), index: 0 });    
    headers.push({ name: "team", caption: t("statColumn_team"), index: 1 });        
    headers.push({ name: "games", caption: t("statColumn_games"), index: 2 });
    headers.push({ name: "goals", caption: t("statColumn_goals"), index: 3 });
    headers.push({ name: "assists", caption: t("statColumn_assists"), index: 4 });
    headers.push({ name: "points", caption: t("statColumn_points"), index: 5 });
    headers.push({ name: "ppg", caption: t("statColumn_pointsPerGame"), index: 6 });
    headers.push({ name: "toi", caption: t("statColumn_toi"), index: 7 });
    //headers.push(t("statColumn_ppToi")); 
    //headers.push(t("statColumn_shToi")); 
    headers.push({ name: "plusMinus", caption: t("statColumn_plusMinus"), index: 10 });
    headers.push({ name: "shotPct", caption: t("statColumn_shotPct"), index: 11 }); 
    headers.push({ name: "faceoffPct", caption: t("statColumn_faceoffPct"), index: 40 }); 
    headers.push({ name: "pim", caption: t("statColumn_penaltyMinutes"), index: 12 });     
    //headers.push(t("statColumn_hits")); 
    //headers.push(t("statColumn_blocked"));     
    headers.push({ name: "position", caption: t("statColumn_primaryPosition"), index: 15 });   
    headers.push({ name: "age", caption: t("statColumn_age"), index: 16 });
    headers.push({ name: "sweaterNumber", caption: t("statColumn_sweaterNumber"), index: 41 });
    headers.push({ name: "contract", caption: t("statColumn_contract"), index: 42 });
    headers.push({ name: "capHit", caption: t("statColumn_capHit"), index: 43 });

    const goalieHeaders = [];          
    goalieHeaders.push({ name: "#", caption: "#", index: -1 });
    goalieHeaders.push({ name: "name", caption: t("statColumn_name"), index: 0 });    
    goalieHeaders.push({ name: "team", caption: t("statColumn_team"), index: 1 });        
    goalieHeaders.push({ name: "games", caption: t("statColumn_games"), index: 2 });    
    goalieHeaders.push({ name: "saves", caption: t("statColumn_saves"), index: 3 });
    goalieHeaders.push({ name: "shotsAgainst", caption: t("statColumn_shotsAgainst"), index: 4 });
    goalieHeaders.push({ name: "savePct", caption: t("statColumn_savePct"), index: 5 });
    goalieHeaders.push({ name: "gaa", caption: t("statColumn_gaa"), index: 6 });
    goalieHeaders.push({ name: "toi", caption: t("statColumn_toi"), index: 7 });
    goalieHeaders.push({ name: "wins", caption: t("statColumn_wins"), index: 8 });
    goalieHeaders.push({ name: "losses", caption: t("statColumn_losses"), index: 9 }); 
    goalieHeaders.push({ name: "ot", caption: t("statColumn_ot"), index: 10 });   
    goalieHeaders.push({ name: "age", caption: t("statColumn_age"), index: 11 });
    goalieHeaders.push({ name: "sweaterNumber", caption: t("statColumn_sweaterNumber"), index: 41 });
    goalieHeaders.push({ name: "contract", caption: t("statColumn_contract"), index: 42 });
    goalieHeaders.push({ name: "capHit", caption: t("statColumn_capHit"), index: 43 });
        
    let skaterCounter = 1;    
    let goalieCounter = 1;    
    let skaters = [];
    let goalies = [];
    let skaterSortColumnIndex = 0;
    let goalieSortColumnIndex = 0;
    let skaterSortCaption = "";
    let goalieSortCaption = "";

    if (statType === "filtered")
    {
        skaters = sort("nameAsc", props.players.filter(p => p.primaryPosition !== "G" && p.games > 0), true);
        skaters = sort(skaterSortOrderValue, skaters, true);
        goalies = sort("nameAsc", props.players.filter(p => p.primaryPosition === "G" && p.games > 0), false);
        goalies = sort(goalieSortOrderValue, goalies, false);
    }
    else
    {
        skaters = sort("nameAsc", props.allPlayers.filter(p => p.primaryPosition !== "G" && p.games > 0), true);
        skaters = sort(skaterSortOrderValue, skaters, true);
        skaters = skaters.slice(0, 200);
        goalies = sort("nameAsc", props.allPlayers.filter(p => p.primaryPosition === "G" && p.games > 0), false);
        goalies = sort(goalieSortOrderValue, goalies, false);
        goalies = goalies.slice(0, 50);
    }
        
    let playerCount = skaters.length;
    let gamesSum = skaters.reduce((total, next) => total + next.games, 0);
    let goalsSum = skaters.reduce((total, next) => total + next.goals, 0);
    let assistsSum = skaters.reduce((total, next) => total + next.assists, 0);
    let pointsSum = skaters.reduce((total, next) => total + next.points, 0);
    let pointsPerGame = skaters.reduce((total, next) => total + next.pointsPerGame, 0) / playerCount;
    let plusMinusSum = skaters.reduce((total, next) => total + next.plusMinus, 0);
    let hitSum = skaters.reduce((total, next) => total + next.hits, 0);
    let pimSum = skaters.reduce((total, next) => total + next.pim, 0);
    let blockedSum = skaters.reduce((total, next) => total + next.blocked, 0);
    let shotPct = skaters.reduce((total, next) => total + next.shotPct, 0) / skaters.length;
    let faceoffPct = skaters.reduce((total, next) => total + next.faceoffPct, 0) / skaters.length;
    let toiSum = skaters.reduce((total, next) => total + next.timeOnIcePerGame, 0);
    let ppToiSum = skaters.reduce((total, next) => total + next.ppToi, 0);
    let shToiSum = skaters.reduce((total, next) => total + next.shToi, 0);
    //let capHitSum = skaters.reduce((total, next) => total + next.shToi, 0);
    let age = skaters.reduce((total, next) => total + next.age, 0) / skaters.length;
    let toiString = Utilities.pad(Math.floor((toiSum / playerCount) / 60), 2) + ':' + Utilities.pad(Math.round((toiSum / playerCount) % 60), 2);
    let ppToiString = Utilities.pad(Math.floor((ppToiSum / playerCount) / 60), 2) + ':' + Utilities.pad(Math.round((ppToiSum / playerCount) % 60), 2);
    let shToiString = Utilities.pad(Math.floor((shToiSum / playerCount) / 60), 2) + ':' + Utilities.pad(Math.round((shToiSum / playerCount) % 60), 2);
    let toiSumString = Math.floor(toiSum / 60) + ':' + Utilities.pad(Math.round((toiSum / playerCount) % 60), 2);
    let ppToiSumString = Math.floor(ppToiSum / 60) + ':' + Utilities.pad(Math.round((ppToiSum / playerCount) % 60), 2);
    let shToiSumString = Math.floor(shToiSum / 60) + ':' + Utilities.pad(Math.round((shToiSum / playerCount) % 60), 2);        
    //let capHitSumString = Math.floor(shToiSum / 60) + ':' + Utilities.pad(Math.round((shToiSum / playerCount) % 60), 2);        

    function getSortCaption(fullColumnCaption) {
        return `${t("stats_sortLabel")}: ${fullColumnCaption}`;
    }

    function sort(order, players, isSkater)
    {
        if (order === "nameDesc")
        {   
            if (isSkater) {
                skaterSortColumnIndex = 0;
                skaterSortCaption = getSortCaption(t("scFull_name"));
            }                
            else {                
                goalieSortColumnIndex = 0;            
                goalieSortCaption = getSortCaption(t("scFull_name"));
            }
            
            return players.sort((a, b) => (b.lastName > a.lastName) - (b.lastName < a.lastName));
        }
        if (order === "nameAsc")
        {
            if (isSkater) {
                skaterSortColumnIndex = 0;
                skaterSortCaption = getSortCaption(t("scFull_name"));
            }                
            else {                
                goalieSortColumnIndex = 0;            
                goalieSortCaption = getSortCaption(t("scFull_name"));
            }

            return players.sort((a, b) => (a.lastName > b.lastName) - (a.lastName < b.lastName));
        }
        if (order === "teamDesc")
        {
            if (isSkater) {
                skaterSortColumnIndex = 1;
                skaterSortCaption = getSortCaption(t("scFull_team"));
            }                
            else {                
                goalieSortColumnIndex = 1;            
                goalieSortCaption = getSortCaption(t("scFull_team"));
            }

            return players.sort((a, b) => (b.team > a.team) - (b.team < a.team));
        }
        if (order === "teamAsc")
        {
            if (isSkater) {
                skaterSortColumnIndex = 1;
                skaterSortCaption = getSortCaption(t("scFull_team"));
            }                
            else {                
                goalieSortColumnIndex = 1;            
                goalieSortCaption = getSortCaption(t("scFull_team"));
            }
            
            return players.sort((a, b) => (a.team > b.team) - (a.team < b.team));
        }
        if (order === "gamesDesc")
        {
            if (isSkater) {
                skaterSortColumnIndex = 2;
                skaterSortCaption = getSortCaption(t("scFull_games"));
            }                
            else {                
                goalieSortColumnIndex = 2;            
                goalieSortCaption = getSortCaption(t("scFull_games"));
            }

            return players.sort((a, b) => b.games - a.games);
        }
        if (order === "gamesAsc")
        {
            if (isSkater) {
                skaterSortColumnIndex = 2;
                skaterSortCaption = getSortCaption(t("scFull_games"));
            }                
            else {                
                goalieSortColumnIndex = 2;            
                goalieSortCaption = getSortCaption(t("scFull_games"));
            }
            return players.sort((a, b) => a.games - b.games);
        }
        if (order === "goalsDesc")
        {
            skaterSortColumnIndex = 3;
            skaterSortCaption = getSortCaption(t("scFull_goals"));
            return players.sort((a, b) => b.goals - a.goals);
        }
        if (order === "goalsAsc")
        {
            skaterSortColumnIndex = 3;
            skaterSortCaption = getSortCaption(t("scFull_goals"));
            return players.sort((a, b) => a.goals - b.goals);
        }
        if (order === "assistsDesc")
        {
            skaterSortColumnIndex = 4;
            skaterSortCaption = getSortCaption(t("scFull_assists"));
            return players.sort((a, b) => b.assists - a.assists);
        }
        if (order === "assistsAsc")
        {
            skaterSortColumnIndex = 4;
            skaterSortCaption = getSortCaption(t("scFull_assists"));
            return players.sort((a, b) => a.assists - b.assists);
        }
        if (order === "pointsDesc")
        {
            skaterSortColumnIndex = 5;
            skaterSortCaption = getSortCaption(t("scFull_points"));
            return players.sort((a, b) => b.points - a.points || b.goals - a.goals);
        }
        if (order === "pointsAsc")
        {
            skaterSortColumnIndex = 5;
            skaterSortCaption = getSortCaption(t("scFull_points"));
            return players.sort((a, b) => a.points - b.points || a.goals - b.goals);
        }
        else if (order === "ppgDesc")
        {
            skaterSortColumnIndex = 6;
            skaterSortCaption = getSortCaption(t("scFull_pointsPerGame"));
            return players.sort((a, b) => b.pointsPerGame - a.pointsPerGame);
        }
        else if (order === "ppgAsc")
        {
            skaterSortColumnIndex = 6;
            skaterSortCaption = getSortCaption(t("scFull_pointsPerGame"));
            return players.sort((a, b) => a.pointsPerGame - b.pointsPerGame);
        }
        else if (order === "toiDesc")
        {         
            if (isSkater) {
                skaterSortColumnIndex = 7;
                skaterSortCaption = getSortCaption(t("scFull_toi"));
            }                
            else {                
                goalieSortColumnIndex = 7;            
                goalieSortCaption = getSortCaption(t("scFull_toi"));
            }

            return players.sort((a, b) => (b.timeOnIcePerGame > a.timeOnIcePerGame) - (b.timeOnIcePerGame < a.timeOnIcePerGame));
        }
        else if (order === "toiAsc")
        {
            if (isSkater) {
                skaterSortColumnIndex = 7;
                skaterSortCaption = getSortCaption(t("scFull_toi"));
            }                
            else {                
                goalieSortColumnIndex = 7;            
                goalieSortCaption = getSortCaption(t("scFull_toi"));
            }

            return players.sort((a, b) => (a.timeOnIcePerGame > b.timeOnIcePerGame) - (a.timeOnIcePerGame < b.timeOnIcePerGame));
        }
        else if (order === "ppToiDesc")
        {
            skaterSortColumnIndex = 8;
            skaterSortCaption = getSortCaption(t("scFull_ppToi"));
            return players.sort((a, b) => (b.ppToi > a.ppToi) - (b.ppToi < a.ppToi));
        }
        else if (order === "ppToiAsc")
        {
            skaterSortColumnIndex = 8;
            skaterSortCaption = getSortCaption(t("scFull_ppToi"));
            return players.sort((a, b) => (a.ppToi > b.ppToi) - (a.ppToi < b.ppToi));
        }
        else if (order === "shToiDesc")
        {
            skaterSortColumnIndex = 9;
            skaterSortCaption = getSortCaption(t("scFull_shToi"));
            return players.sort((a, b) => (b.shToi > a.shToi) - (b.shToi < a.shToi));
        }
        else if (order === "shToiAsc")
        {
            skaterSortColumnIndex = 9;
            skaterSortCaption = getSortCaption(t("scFull_shToi"));
            return players.sort((a, b) => (a.shToi > b.shToi) - (a.shToi < b.shToi));
        }
        else if (order === "plusMinusDesc")
        {
            skaterSortColumnIndex = 10;
            skaterSortCaption = getSortCaption(t("scFull_plusMinus"));
            return players.sort((a, b) => b.plusMinus - a.plusMinus);
        }
        else if (order === "plusMinusAsc")
        {
            skaterSortColumnIndex = 10;
            skaterSortCaption = getSortCaption(t("scFull_plusMinus"));
            return players.sort((a, b) => a.plusMinus - b.plusMinus);
        }
        else if (order === "shotPctDesc")
        {
            skaterSortColumnIndex = 11;
            skaterSortCaption = getSortCaption(t("scFull_shotPct"));
            return players.sort((a, b) => b.shotPct - a.shotPct);
        }
        else if (order === "shotPctAsc")
        {
            skaterSortColumnIndex = 11;
            skaterSortCaption = getSortCaption(t("scFull_shotPct"));
            return players.sort((a, b) => a.shotPct - b.shotPct);
        }
        else if (order === "pimDesc")
        {
            skaterSortColumnIndex = 12;
            skaterSortCaption = getSortCaption(t("scFull_pim"));
            return players.sort((a, b) => b.pim - a.pim);
        }
        else if (order === "pimAsc")
        {
            skaterSortColumnIndex = 12;
            skaterSortCaption = getSortCaption(t("scFull_pim"));
            return players.sort((a, b) => a.pim - b.pim);
        }
        else if (order === "hitsDesc")
        {
            skaterSortColumnIndex = 13;
            skaterSortCaption = getSortCaption(t("scFull_hits"));
            return players.sort((a, b) => b.hits - a.hits);
        }
        else if (order === "hitsAsc")
        {
            skaterSortColumnIndex = 13;
            skaterSortCaption = getSortCaption(t("scFull_hits"));
            return players.sort((a, b) => a.hits - b.hits);
        }
        else if (order === "blockedDesc")
        {
            skaterSortColumnIndex = 14;
            skaterSortCaption = getSortCaption(t("scFull_blocked"));
            return players.sort((a, b) => b.blocked - a.blocked);
        }
        else if (order === "blockedAsc")
        {
            skaterSortColumnIndex = 14;
            skaterSortCaption = getSortCaption(t("scFull_blocked"));
            return players.sort((a, b) => a.blocked - b.blocked);
        }
        else if (order === "positionDesc")
        {
            skaterSortColumnIndex = 15;
            skaterSortCaption = getSortCaption(t("scFull_primaryPosition"));
            return players.sort((a, b) => (b.primaryPosition > a.primaryPosition) - (b.primaryPosition < a.primaryPosition));
        }
        else if (order === "positionAsc")
        {
            skaterSortColumnIndex = 15;
            skaterSortCaption = getSortCaption(t("scFull_primaryPosition"));
            return players.sort((a, b) => (a.primaryPosition > b.primaryPosition) - (a.primaryPosition < b.primaryPosition));
        }
        else if (order === "ageDesc")
        {
            if (isSkater) {
                skaterSortColumnIndex = 16;
                skaterSortCaption = getSortCaption(t("statColumn_age"));
            }                
            else {                
                goalieSortColumnIndex = 11;            
                goalieSortCaption = getSortCaption(t("statColumn_age"));
            }

            return players.sort((a, b) => b.age - a.age);
        }
        else if (order === "ageAsc")
        {
            if (isSkater) {
                skaterSortColumnIndex = 16;
                skaterSortCaption = getSortCaption(t("statColumn_age"));
            }                
            else {                
                goalieSortColumnIndex = 11;            
                goalieSortCaption = getSortCaption(t("statColumn_age"));
            }
            return players.sort((a, b) => a.age - b.age);
        }
        else if (order === "faceoffPctDesc")
        {
            skaterSortColumnIndex = 40;
            skaterSortCaption = getSortCaption(t("scFull_faceoffPct"));
            return players.sort((a, b) => b.faceoffPct - a.faceoffPct);
        }
        else if (order === "faceoffPctAsc")
        {
            skaterSortColumnIndex = 40;
            skaterSortCaption = getSortCaption(t("scFull_faceoffPct"));
            return players.sort((a, b) => a.faceoffPct - b.faceoffPct);
        }
        else if (order === "shotsAgainstDesc")
        {
            goalieSortColumnIndex = 4;
            goalieSortCaption = getSortCaption(t("scFull_shotsAgainst"));
            return players.sort((a, b) => b.shotsAgainst - a.shotsAgainst);
        }
        else if (order === "shotsAgainstAsc")
        {
            goalieSortColumnIndex = 4;
            goalieSortCaption = getSortCaption(t("scFull_shotsAgainst"));
            return players.sort((a, b) => a.shotsAgainst - b.shotsAgainst);
        }
        else if (order === "savesDesc")
        {
            goalieSortColumnIndex = 3;
            goalieSortCaption = getSortCaption(t("scFull_saves"));
            return players.sort((a, b) => b.saves - a.saves);
        }
        else if (order === "savesAsc")
        {
            goalieSortColumnIndex = 3;
            goalieSortCaption = getSortCaption(t("scFull_saves"));
            return players.sort((a, b) => a.saves - b.saves);
        }
        else if (order === "savePctDesc")
        {
            goalieSortColumnIndex = 5;
            goalieSortCaption = getSortCaption(t("scFull_savePct"));
            return players.sort((a, b) => b.savePct - a.savePct);
        }
        else if (order === "savePctAsc")
        {
            goalieSortColumnIndex = 5;
            goalieSortCaption = getSortCaption(t("scFull_savePct"));
            return players.sort((a, b) => a.savePct - b.savePct);
        }
        else if (order === "winsDesc")
        {
            goalieSortColumnIndex = 8;
            goalieSortCaption = getSortCaption(t("scFull_wins"));
            return players.sort((a, b) => b.wins - a.wins);
        }
        else if (order === "winsAsc")
        {
            goalieSortColumnIndex = 8;
            goalieSortCaption = getSortCaption(t("scFull_wins"));
            return players.sort((a, b) => a.wins - b.wins);
        }
        else if (order === "lossesDesc")
        {
            goalieSortColumnIndex = 9;
            goalieSortCaption = getSortCaption(t("scFull_losses"));
            return players.sort((a, b) => b.losses - a.losses);
        }
        else if (order === "lossesAsc")
        {
            goalieSortColumnIndex = 9;
            goalieSortCaption = getSortCaption(t("scFull_losses"));
            return players.sort((a, b) => a.losses - b.losses);
        }
        else if (order === "otDesc")
        {
            goalieSortColumnIndex = 10;
            goalieSortCaption = getSortCaption(t("scFull_ot"));
            return players.sort((a, b) => b.ot - a.ot);
        }
        else if (order === "otAsc")
        {
            goalieSortColumnIndex = 10;
            goalieSortCaption = getSortCaption(t("scFull_ot"));
            return players.sort((a, b) => a.ot - b.ot);
        }
        else if (order === "gaaDesc")
        {
            goalieSortColumnIndex = 6;
            goalieSortCaption = getSortCaption(t("scFull_gaa"));
            return players.sort((a, b) => b.gaa - a.gaa);
        }
        else if (order === "gaaAsc")
        {
            goalieSortColumnIndex = 6;
            goalieSortCaption = getSortCaption(t("scFull_gaa"));
            return players.sort((a, b) => a.gaa - b.gaa);
        }
        else if (order === "capHitDesc")
        {
            if (isSkater) {
                skaterSortColumnIndex = 43;
                skaterSortCaption = getSortCaption(t("scFull_capHit"));
            }                
            else {                
                goalieSortColumnIndex = 43;            
                goalieSortCaption = getSortCaption(t("scFull_capHit"));
            }            
            return players.sort((a, b) => getCapHitNumber(b.capHit) - getCapHitNumber(a.capHit));
        }
        else if (order === "capHitAsc")
        {
            if (isSkater) {
                skaterSortColumnIndex = 43;
                skaterSortCaption = getSortCaption(t("scFull_capHit"));
            }                
            else {                
                goalieSortColumnIndex = 43;            
                goalieSortCaption = getSortCaption(t("scFull_capHit"));
            }
            return players.sort((a, b) => getCapHitNumber(a.capHit) - getCapHitNumber(b.capHit));
        }
        else if (order === "contractDesc")
        {
            if (isSkater) {
                skaterSortColumnIndex = 42;
                skaterSortCaption = getSortCaption(t("scFull_contract"));
            }                
            else {                
                goalieSortColumnIndex = 42;            
                goalieSortCaption = getSortCaption(t("scFull_contract"));
            }            
            return players.sort((a, b) => getContractNumber(b.contract) - getContractNumber(a.contract));
        }
        else if (order === "contractAsc")
        {
            if (isSkater) {
                skaterSortColumnIndex = 42;
                skaterSortCaption = getSortCaption(t("scFull_contract"));
            }                
            else {                
                goalieSortColumnIndex = 42;            
                goalieSortCaption = getSortCaption(t("scFull_contract"));
            }
            return players.sort((a, b) => getContractNumber(a.contract) - getContractNumber(b.contract));
        }
        else if (order === "sweaterNumberDesc")
            {
                if (isSkater) {
                    skaterSortColumnIndex = 41;
                    skaterSortCaption = getSortCaption(t("scFull_sweaterNumber"));
                }                
                else {                
                    goalieSortColumnIndex = 41;            
                    goalieSortCaption = getSortCaption(t("scFull_sweaterNumber"));
                }            
                return players.sort((a, b) => b.sweaterNumber - a.sweaterNumber);
            }
            else if (order === "sweaterNumberAsc")
            {
                if (isSkater) {
                    skaterSortColumnIndex = 41;
                    skaterSortCaption = getSortCaption(t("scFull_sweaterNumber"));
                }                
                else {                
                    goalieSortColumnIndex = 41;            
                    goalieSortCaption = getSortCaption(t("scFull_sweaterNumber"));
                }
                return players.sort((a, b) => a.sweaterNumber - b.sweaterNumber);
            }
        
        return players.sort((a, b) => (a.displayName > b.displayName) - (a.displayName < b.displayName));
    }    

    function getCapHitNumber(capHitString) {        
        return (capHitString !== undefined) ? Number(capHitString.replace("$", "").replaceAll(",", "")) : 0;
    }

    function getContractNumber(contractString) {        
        return (contractString !== undefined) ? Number(contractString.substring(0, 1)) : 0;
    }

    function getTotalSeconds(timeString)
    {
        if (timeString === undefined || timeString === null || timeString === "")
        {
            return 0;
        }
        
        const minutes = timeString.substring(0,2);
        const seconds = timeString.substring(3,5);
        return + 60 * minutes + + seconds;  
    }

    function getTimeString(seconds)
    {
        if (seconds === undefined)
        {
            return "00:00";
        }
        
        return `${Utilities.pad((seconds / 60).toFixed(0), 2)}:${Utilities.pad((seconds % 60).toFixed(0), 2)}`        
    }
    
    function GetHighlightStyle(player, index)
    {
        if (player !== undefined && player.nationality === selectedCountry && statType === "all")
        {         
            if (index === skaterSortColumnIndex)   
            {
                return { color: "deepskyblue", backgroundColor: "#383838" };    
            }
            return { color: "deepskyblue" };
        }
        if (index === skaterSortColumnIndex)   
        {
            return { color: "white", backgroundColor: "#383838" };    
        }
        return { color: "white" };
    }

    function GetSkaterHeaderHighlightStyle(index)
    {
        return index === skaterSortColumnIndex ? { backgroundColor: "#555555" } : { backgroundColor: "#000000" };            
    }

    function GetGoalieHeaderHighlightStyle(index)
    {
        return index === goalieSortColumnIndex ? { backgroundColor: "#555555" } : { backgroundColor: "#000000" };            
    }

    function GetHighlightStyleGoalie(player, index)
    {
        if (player !== undefined && player.nationality === selectedCountry && statType === "all")
        {         
            if (index === goalieSortColumnIndex)   
            {
                return { color: "deepskyblue", backgroundColor: "#383838" };    
            }
            return { color: "deepskyblue" };
        }
        if (index === goalieSortColumnIndex)   
        {
            return { color: "white", backgroundColor: "#383838" };    
        }
        return { color: "white" };
    }

    function GetAlternateRowStyle(index)
    {
        if (index % 2 === 0)
        {
            return { backgroundColor: "#1f1f1f" };
        }
        return { backgroundColor: "#101010" };
    }

    const getPlayerDisplayName = (player, displayName) => 
    {        
        if (player !== undefined)
            displayName = displayName + " " + "*".repeat(player.injury);
            
        return displayName;
    }
    
    return (
        isLoading ? loadingContent :
        <div className='TableContainer'>            
            <table className='Stats__headerTable'>
                <tr>
                    <td style={{ width: `20%`, whitespace: `nowrap;` }}>{t("stats_seasonStats")}</td>
                    <td/>
                    <td style={{ alignContent: `end`, width: `40%`, whitespace: `nowrap;`, marginLeft: `24px;`, maxWidth: `300px;` }}>  
                        <div style={{ textAlign: `right`}}>       
                            <label className={filteredStyle} id="filtered" onClick={onStatTypeSelected}>{t("stats_showSelected")}</label>
                            <label> | </label>                                        
                            <label className={allStyle} id="all" onClick={onStatTypeSelected}>{t("stats_showAll")}</label>
                        </div>                    
                    </td>                                                              
                </tr>
                <tr>                    
                    <td style={{ width: `30%`, whitespace: `nowrap;` }}>
                        <h5>{t("stats_skaters")}</h5>            
                    </td>                                        
                    <td/>                    
                    <td style={{ width: `40%`, fontSize: `smaller`, textAlign: `right`, whitespace: `nowrap;`, marginLeft: `24px;`, maxWidth: `100px;` }}>                    
                        {skaterSortCaption}
                    </td>                                       
                </tr>
            </table>  
            <div className="TableContainer">
                <table className="Table_Stats">
                    <tr key={"header"}>
                    {headers.map((header) => (
                    <th style={GetSkaterHeaderHighlightStyle(header.index)} onClick={() => handleSkaterOrderChangeHeader(header.name)}>{header.caption}</th>
                    ))}
                    </tr>
                    {skaters.map((item) => (                        
                    <tr className="TableRow" style={GetAlternateRowStyle(skaterCounter)} key={item.id} >        
                        <td className="LeftJustifiedCell" style={GetHighlightStyle(item, -1)}>{skaterCounter++}</td>
                        <td className="LeftJustifiedCell" style={GetHighlightStyle(item, 0)}>{getPlayerDisplayName(item, `${item.lastName} ${item.firstName.at(0)}`)}</td>                                                          
                        <td className="LeftJustifiedCell" style={GetHighlightStyle(item, 1)}>{item.team}</td>                    
                        <td className="RightJustifiedCell" style={GetHighlightStyle(item, 2)}>{item.games}</td>
                        <td className="RightJustifiedCell" style={GetHighlightStyle(item, 3)}>{item.goals}</td>
                        <td className="RightJustifiedCell" style={GetHighlightStyle(item, 4)}>{item.assists}</td>
                        <td className="RightJustifiedCell" style={GetHighlightStyle(item, 5)}>{item.points}</td>                    
                        <td className="RightJustifiedCell" style={GetHighlightStyle(item, 6)}>{item.pointsPerGame.toFixed(2)}</td>
                        <td className="RightJustifiedCell" style={GetHighlightStyle(item, 7)}>{getTimeString(item.timeOnIcePerGame)}</td>                        
                        {/* <td className="RightJustifiedCell" style={GetHighlightStyle(item, 8)}>{item.ppToi}</td>                      
                        <td className="RightJustifiedCell" style={GetHighlightStyle(item, 9)}>{item.shToi}</td>                           */}
                        <td className="RightJustifiedCell" style={GetHighlightStyle(item, 10)}>{item.plusMinus}</td> 
                        <td className="RightJustifiedCell" style={GetHighlightStyle(item, 11)}>{item.shotPct?.toFixed(1)}</td>
                        <td className="RightJustifiedCell" style={GetHighlightStyle(item, 40)}>{item.faceoffPct?.toFixed(1)}</td>
                        <td className="RightJustifiedCell" style={GetHighlightStyle(item, 12)}>{item.pim}</td> 
                        {/* <td className="RightJustifiedCell" style={GetHighlightStyle(item, 13)}>{item.hits}</td> 
                        <td className="RightJustifiedCell" style={GetHighlightStyle(item, 14)}>{item.blocked}</td>                          */}
                        <td className="RightJustifiedCell" style={GetHighlightStyle(item, 15)}>{item.primaryPosition}</td>                    
                        <td className="RightJustifiedCell" style={GetHighlightStyle(item, 16)}>{item.age}</td>                    
                        <td className="RightJustifiedCell" style={GetHighlightStyle(item, 41)}>{item.sweaterNumber}</td>   
                        <td className="RightJustifiedCell" style={GetHighlightStyle(item, 42)}>{item.contract}</td>   
                        <td className="RightJustifiedCell" style={GetHighlightStyle(item, 43)}>{item.capHit}</td>   
                    </tr>
                    ))}                                        
                </table>                
                <table className="Table_Stats" style={{ marginTop: `4px` }}>
                    <tr key={"header"}>
                        {headers.map((header) => (
                        <th className="HeaderCell" onClick={() => handleSkaterOrderChangeHeader(header.name)}>{header.caption}</th>
                        ))}
                    </tr>
                    <tr className="TableRow">    
                        <td className="RightJustifiedCell"></td>
                        <td colSpan="2" className="LeftJustifiedCell">{t("stats_averageJoe")}</td>                         
                        <td className="RightJustifiedCell">{(gamesSum / playerCount).toFixed(0)}</td>
                        <td className="RightJustifiedCell">{(goalsSum / playerCount).toFixed(0)}</td>
                        <td className="RightJustifiedCell">{(assistsSum / playerCount).toFixed(0)}</td>                    
                        <td className="RightJustifiedCell">{(pointsSum / playerCount).toFixed(0)}</td>                    
                        <td className="RightJustifiedCell">{pointsPerGame.toFixed(2)}</td>
                        <td className="RightJustifiedCell">{toiString}</td>
                        {/* <td className="RightJustifiedCell">{ppToiString}</td> 
                        <td className="RightJustifiedCell">{shToiString}</td>  */}
                        <td className="RightJustifiedCell">{(plusMinusSum / playerCount).toFixed(1)}</td>
                        <td className="RightJustifiedCell">{shotPct?.toFixed(1)}</td>
                        <td className="RightJustifiedCell">{faceoffPct?.toFixed(1)}</td>
                        <td className="RightJustifiedCell">{(pimSum / playerCount).toFixed(1)}</td>
                        {/* <td className="RightJustifiedCell">{(hitSum / playerCount).toFixed(1)}</td>
                        <td className="RightJustifiedCell">{(blockedSum / playerCount).toFixed(1)}</td>                         */}
                        <td className="RightJustifiedCell">-</td>    
                        <td className="RightJustifiedCell">{age.toFixed(1)}</td>
                    </tr>
                    <tr className="TableRow">        
                        <td className="RightJustifiedCell"></td>
                        <td colSpan="2" className="LeftJustifiedCell">{t("stats_summitSam")}</td>                         
                        <td className="RightJustifiedCell">{gamesSum}</td>
                        <td className="RightJustifiedCell">{goalsSum}</td>
                        <td className="RightJustifiedCell">{assistsSum}</td>                    
                        <td className="RightJustifiedCell">{pointsSum}</td>                    
                        <td className="RightJustifiedCell">-</td>
                        <td className="RightJustifiedCell">{toiSumString}</td>
                        {/* <td className="RightJustifiedCell">{ppToiSumString}</td>
                        <td className="RightJustifiedCell">{shToiSumString}</td> */}
                        <td className="RightJustifiedCell">{plusMinusSum}</td>
                        <td className="RightJustifiedCell">-</td>
                        <td className="RightJustifiedCell">-</td>
                        <td className="RightJustifiedCell">{pimSum}</td>
                        {/* <td className="RightJustifiedCell">{hitSum}</td>
                        <td className="RightJustifiedCell">{blockedSum}</td> */}
                        <td className="RightJustifiedCell">-</td>
                        {/* <td className="RightJustifiedCell">-</td>                                     */}
                    </tr>
                </table>                
                <table className='LatestGameDay__headerTable'>
                <tr>                    
                    <td style={{ width: `1%`, whitespace: `nowrap;` }}>
                        <h5>{t("stats_goalies")}</h5>            
                    </td>                                        
                    <td/>
                    <td style={{ width: `40%`, fontSize: `smaller`, textAlign: `right`, whitespace: `nowrap;`, marginLeft: `24px;`, maxWidth: `100px;` }}>                    
                        {goalieSortCaption}
                    </td>                    
                </tr>
            </table>  
                <table className="Table_Stats">
                    <tr key={"header"}>
                    {goalieHeaders.map((header) => (
                        <th style={GetGoalieHeaderHighlightStyle(header.index)} className="HeaderCell" onClick={() => handleGoalieOrderChangeHeader(header.name)}>{header.caption}</th>
                        ))}
                    </tr>
                    {goalies.map((item) => (
                    <tr className="TableRow" style={GetAlternateRowStyle(goalieCounter)} key={item.id} >        
                        <td className="RightJustifiedCell" style={GetHighlightStyleGoalie(item, -1)}>{goalieCounter++}</td>
                        <td className="LeftJustifiedCell" style={GetHighlightStyleGoalie(item, 0)}>{getPlayerDisplayName(item, `${item.lastName} ${item.firstName.at(0)}`)}</td>                                                          
                        <td className="LeftJustifiedCell" style={GetHighlightStyleGoalie(item, 1)}>{item.team}</td>                    
                        <td className="RightJustifiedCell" style={GetHighlightStyleGoalie(item, 2)}>{item.games}</td>
                        <td className="RightJustifiedCell" style={GetHighlightStyleGoalie(item, 3)}>{item.saves}</td>
                        <td className="RightJustifiedCell" style={GetHighlightStyleGoalie(item, 4)}>{item.shotsAgainst}</td>
                        <td className="RightJustifiedCell" style={GetHighlightStyleGoalie(item, 5)}>{(item.savePct * 100).toFixed(1)}</td>                    
                        <td className="RightJustifiedCell" style={GetHighlightStyleGoalie(item, 6)}>{item.gaa?.toFixed(2)}</td>
                        <td className="RightJustifiedCell" style={GetHighlightStyleGoalie(item, 7)}>{Utilities.getTimeString(item.timeOnIcePerGame)}</td>      
                        <td className="RightJustifiedCell" style={GetHighlightStyleGoalie(item, 8)}>{item.wins}</td>
                        <td className="RightJustifiedCell" style={GetHighlightStyleGoalie(item, 9)}>{item.losses}</td> 
                        <td className="RightJustifiedCell" style={GetHighlightStyleGoalie(item, 10)}>{item.ot}</td>                                                          
                        <td className="RightJustifiedCell" style={GetHighlightStyleGoalie(item, 11)}>{item.age}</td>
                        <td className="RightJustifiedCell" style={GetHighlightStyleGoalie(item, 41)}>{item.sweaterNumber}</td>   
                        <td className="RightJustifiedCell" style={GetHighlightStyleGoalie(item, 42)}>{item.contract}</td>   
                        <td className="RightJustifiedCell" style={GetHighlightStyleGoalie(item, 43)}>{item.capHit}</td>   
                    </tr>
                    ))}                                        
                </table>  
                <br/>  
                <Legend scores={props.scores}
                        players={props.players}
                        allPlayers={props.allPlayers}
                        showAllPlayers={statType === "all"}
                        showAlways={true}/>            
            </div>  
        </div>        
    );
}

export default Stats;
