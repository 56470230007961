import './NationalTeam.css';
import React from 'react';
import { useTranslation } from "react-i18next";
import NationalTeamPlayer from './NationalTeamPlayer';
import SettingStorage from '../classes/SettingsStorage';

const NationalTeam = (props) => {    
    const { t } = useTranslation();
    let filteredStyle;
    let allStyle;
    let ntType = SettingStorage.getStorageValue("nhlcountries_nationalTeamType", "all");        
    const [ntTypeValue, setNtTypeValue] = React.useState(ntType);   
    

    const updateStyles = (id) =>
    {        
        filteredStyle = id === "filtered" ? "NtTypeTextSelected" : "NtTypeText";
        allStyle = id === "all" ? "NtTypeTextSelected" : "NtTypeText";
    }

    updateStyles(ntType);

    const onNtTypeSelected = async (e) => {                
        let id = e.target.id;
        SettingStorage.setStorageValue("nhlcountries_nationalTeamType", id);                
        setNtTypeValue(id);  
        updateStyles(id);
    }
    
    let ntPlayers = [];
    let allTeams = [...props.standings[0].teams, ...props.standings[1].teams, ...props.standings[2].teams, ...props.standings[3].teams];    
    if (ntTypeValue !== "all") {
        ntPlayers = props.players.filter(p => allTeams.find(t => t.id === p.teamId && t.wcPos > 2) !== undefined && p.isActive);
    }
    else {
        ntPlayers = props.players;
    }

    let lwStack = [];
    let rwStack = [];
    let cStack = [];    
    let spareForwardStack = [];
    let dStack = [];
    let gStack = [];
    let lowGameF = [];
    let lowGameD = [];
    let lowGameG = [];    
    let skaters = ntPlayers.filter(p => p.primaryPosition !== "G");
    let goalies = ntPlayers.filter(p => p.primaryPosition === "G");
    skaters = skaters.sort((a, b) => b.pointsPerGame - a.pointsPerGame);
    goalies = goalies.sort((a, b) => {
        return (b.savePct * 100 - b.gaa) - (a.savePct * 100 - a.gaa);
    });

    const addToCapHit = (salary) => {        
        teamCapHit += salary;
    }

    const skaterGamesMax = Math.max.apply(Math, skaters.map(player => player.games));
    const goalieGamesMax = Math.max.apply(Math, goalies.map(player => player.games));    
    let teamCapHit = 0;

    skaters.filter(p => p.games > 0).forEach(player => {        
        let ratio = player.games / skaterGamesMax;
        if (skaterGamesMax > 5 && ratio < 0.3)
        {
            if (player.primaryPosition === 'D')
            {
                lowGameD.push(player);
            }
            else{
                lowGameF.push(player);
            }
        }
        else if (player.primaryPosition === 'C')
        {
            if (cStack.length < 4)
            {
                cStack.push(player);
                addToCapHit(player.capHitNumber);
            }
            else if (rwStack.length < 4)
            {
                rwStack.push(player);
                addToCapHit(player.capHitNumber);
            }
            else if (lwStack.length < 4)
            {
                lwStack.push(player);
                addToCapHit(player.capHitNumber);
            }
            else
            {
                spareForwardStack.push(player);
            }            
        }
        else if (player.primaryPosition === 'R')
        {
            if (rwStack.length < 4)
            {
                rwStack.push(player);
                addToCapHit(player.capHitNumber);
            }
            else if (lwStack.length < 4)
            {
                lwStack.push(player);
                addToCapHit(player.capHitNumber);
            }
            else
            {
                spareForwardStack.push(player);
            }
        }
        else if (player.primaryPosition === 'L')
        {
            if (lwStack.length < 4)
            {
                lwStack.push(player);
                addToCapHit(player.capHitNumber);
            }
            else if (rwStack.length < 4)
            {
                rwStack.push(player);
                addToCapHit(player.capHitNumber);
            }
            else
            {
                spareForwardStack.push(player);
            }
        }
        else if (player.primaryPosition === 'D' && dStack.length < 7)
        {
            dStack.push(player);
            addToCapHit(player.capHitNumber);
        }
    });
    
    if (dStack.length < 7)
    {
        while (dStack.length < 7 && lowGameD.length > 0)
        {
            const dman = lowGameD.shift();
            dStack.push(dman);
            addToCapHit(dman.capHitNumber);
        }
    }
    while (spareForwardStack.length < 2)
    {
        let lowGamePlayer = lowGameF.shift();

        if (lowGamePlayer !== undefined)
        {
            if (lowGamePlayer.primaryPosition === 'C')
            {
                if (cStack.length < 4)
                {
                    cStack.push(lowGamePlayer);
                    addToCapHit(lowGamePlayer.capHitNumber);
                }
                else if (rwStack.length < 4)
                {
                    rwStack.push(lowGamePlayer);
                    addToCapHit(lowGamePlayer.capHitNumber);
                }
                else if (lwStack.length < 4)
                {
                    lwStack.push(lowGamePlayer);
                    addToCapHit(lowGamePlayer.capHitNumber);
                }
                else
                {
                    spareForwardStack.push(lowGamePlayer);
                }                            
            }
            else if (lowGamePlayer.primaryPosition === 'R')
            {
                if (rwStack.length < 4)
                {
                    rwStack.push(lowGamePlayer);
                    addToCapHit(lowGamePlayer.capHitNumber);
                }
                else if (lwStack.length < 4)
                {
                    lwStack.push(lowGamePlayer);
                    addToCapHit(lowGamePlayer.capHitNumber);
                }
                else
                {
                    spareForwardStack.push(lowGamePlayer);
                }
            }
            else if (lowGamePlayer.primaryPosition === 'L')
            {
                if (lwStack.length < 4)
                {
                    lwStack.push(lowGamePlayer);
                    addToCapHit(lowGamePlayer.capHitNumber);
                }
                else if (rwStack.length < 4)
                {
                    rwStack.push(lowGamePlayer);
                    addToCapHit(lowGamePlayer.capHitNumber);
                }
                else
                {
                    spareForwardStack.push(lowGamePlayer);
                }
            }
        }             
        else
        {
            break;
        }
    }
    
    while (lowGameF.length > 0 && lowGameF[lowGameF.length - 1].pointsPerGame < spareForwardStack[1].pointsPerGame)
    {
        lowGameF.pop();
    }
    while (lowGameD.length > 0 && lowGameD[lowGameD.length - 1].pointsPerGame < dStack[6].pointsPerGame)
    {
        lowGameD.pop();
    }    

    goalies.filter(p => p.games > 0).forEach(goalie => {        
        let ratio = goalie.games / goalieGamesMax;
        if (goalieGamesMax > 4 && ratio < 0.3)
        {
            lowGameG.push(goalie);
        }
        else if (gStack.length < 3)
        {
            gStack.push(goalie);
            addToCapHit(goalie.capHitNumber);
        }
    });

    if (gStack.length < 3)
    {
        while (gStack.length < 3 && lowGameG.length > 0)
        {
            const goalie = lowGameG.shift();
            gStack.push(goalie);
            addToCapHit(goalie.capHitNumber);
        }
    }

    while (lowGameG.length > 0 && lowGameG[lowGameG.length - 1].savePct < gStack[1].savePct)
    {
        lowGameG.pop();
    }

    let showLowGameD = lowGameD.length > 0 && lowGameD[0].pointsPerGame > dStack[6].pointsPerGame;
    let showLowGameF = lowGameF.length > 0 && lowGameF[0].pointsPerGame > spareForwardStack[1].pointsPerGame;
    let showLowGameG = lowGameG.length > 0 && lowGameG[0].savePct > gStack[2].savePct;

    props.players.sort((a, b) => b.points - a.points);
    
    const getImageAddress = (player) => {        
        return player !== undefined ? `https://assets.nhle.com/mugs/nhl/20232024/${player.team}/${player.playerId}.png` : undefined;
    }
    
    return (
        <div>
            <table className='Stats__headerTable'>
                <tr>               
                    <td style={{ fontSize: 'x-small' }}>{t("nationalTeam_capHit")} ${(teamCapHit / 1000000).toFixed(1)}M</td>     
                    <td style={{ alignContent: `end`, width: `40%`, whitespace: `nowrap;`, marginLeft: `24px;`, maxWidth: `300px;` }}>  
                        <div style={{ textAlign: `right`}}>                                   
                            <label className={allStyle} id="all" onClick={onNtTypeSelected}>{t("nationalTeam_showAll")}</label>
                            <label> | </label>                    
                            <label className={filteredStyle} id="filtered" onClick={onNtTypeSelected}>{t("nationalTeam_showFiltered")}</label>                                                
                        </div>                    
                    </td>                                                              
                </tr>                
            </table>  
            <table className="Table">                        
                <tr>                            
                    <td className="LineCell"><NationalTeamPlayer player={lwStack[0]}/></td>
                    <td className="LineCell"><NationalTeamPlayer player={cStack[0]}/></td>
                    <td className="LineCell"><NationalTeamPlayer player={rwStack[0]}/></td>
                </tr>
                <tr>                            
                    <td className="LineCell"><NationalTeamPlayer player={lwStack[1]}/></td>
                    <td className="LineCell"><NationalTeamPlayer player={cStack[1]}/></td>
                    <td className="LineCell"><NationalTeamPlayer player={rwStack[1]}/></td>
                </tr>
                <tr>                            
                    <td className="LineCell"><NationalTeamPlayer player={lwStack[2]}/></td>
                    <td className="LineCell"><NationalTeamPlayer player={cStack[2]}/></td>
                    <td className="LineCell"><NationalTeamPlayer player={rwStack[2]}/></td>
                </tr>
                <tr>                            
                    <td className="LineCell"><NationalTeamPlayer player={lwStack[3]}/></td>
                    <td className="LineCell"><NationalTeamPlayer player={cStack[3]}/></td>
                    <td className="LineCell"><NationalTeamPlayer player={rwStack[3]}/></td>
                </tr>                
            </table>
            <br/>
            <table className="Table">                
                <tr>                            
                    <td className="LineCell"><NationalTeamPlayer player={dStack[0]}/></td>
                    <td className="LineCell"><NationalTeamPlayer player={dStack[1]}/></td>                    
                </tr>
                <tr>                            
                <td className="LineCell"><NationalTeamPlayer player={dStack[2]}/></td>
                <td className="LineCell"><NationalTeamPlayer player={dStack[3]}/></td>                 
                </tr>
                <tr>                            
                <td className="LineCell"><NationalTeamPlayer player={dStack[4]}/></td>
                <td className="LineCell"><NationalTeamPlayer player={dStack[5]}/></td>                 
                </tr>
            </table>
            <br/>
            <table className="Table">                
                <tr>                            
                    <td className="LineCell"><NationalTeamPlayer player={gStack[0]}/></td>
                </tr>
                <tr>                            
                    <td className="LineCell"><NationalTeamPlayer player={gStack[1]}/></td>                    
                </tr>
            </table>
            {
                (spareForwardStack.length > 0 || dStack.length > 6) &&
                <div>
                    <br/>            
                    <table>
                        <tr key={"header"}>
                            <th className="LeftJustifiedCell">{t("nationalTeam_reserve")}</th>                    
                        </tr>
                        <tr>
                            <td className="LeftJustifiedPlayerCell"><NationalTeamPlayer player={spareForwardStack[0]}/></td>
                            <td className="LeftJustifiedPlayerCell"><NationalTeamPlayer player={spareForwardStack[1]}/></td>
                        </tr>                
                        <tr>                            
                            <td className="LeftJustifiedPlayerCell"><NationalTeamPlayer player={dStack[6]}/></td>                                                
                        </tr>                
                        <tr>                            
                            <td className="LeftJustifiedPlayerCell"><NationalTeamPlayer player={gStack[2]}/></td>    
                        </tr> 
                    </table>
                </div>                
            }                        
            {
                (showLowGameF || showLowGameD || showLowGameG) &&
                <div>
                    <br/>
                    <table>
                        <tr key={"header"}>
                            <th className="LeftJustifiedCell" colSpan="3">{t("nationalTeam_notEnoughGames")}</th>
                        </tr>
                        {
                            (showLowGameF) &&
                            <tr>
                                <td className="LeftJustifiedPlayerCell"><NationalTeamPlayer player={lowGameF[0]}/></td>
                                <td className="LeftJustifiedPlayerCell"><NationalTeamPlayer player={lowGameF[1]}/></td>
                                <td className="LeftJustifiedPlayerCell"><NationalTeamPlayer player={lowGameF[2]}/></td>                                
                            </tr>                
                        }                        
                        {
                            (showLowGameD) &&
                            <tr>
                                <td className="LeftJustifiedPlayerCell"><NationalTeamPlayer player={lowGameD[0]}/></td>
                                <td className="LeftJustifiedPlayerCell"><NationalTeamPlayer player={lowGameD[1]}/></td>               
                                <td className="LeftJustifiedPlayerCell"><NationalTeamPlayer player={lowGameD[2]}/></td>             
                            </tr>
                        }                        
                        {
                            (showLowGameG) &&
                            <tr>
                                <td className="LeftJustifiedPlayerCell"><NationalTeamPlayer player={lowGameG[0]}/></td>
                                <td className="LeftJustifiedPlayerCell"><NationalTeamPlayer player={lowGameG[1]}/></td>    
                                <td className="LeftJustifiedPlayerCell"><NationalTeamPlayer player={lowGameG[2]}/></td>             
                            </tr>
                        }     
                    </table>
                </div>                
            }            
        </div>        
    );
}

export default NationalTeam;