class PlayerModel {
    get playerId(){
        return this._playerId;
    }

    set playerId(newValue){
        this._playerId = newValue;
    }

    get isActive(){
        return this._isActive;
    }

    set isActive(newValue){
        this._isActive = newValue;
    }
    
    get firstName(){
        return this._firstName;
    }

    set firstName(newValue){
        this._firstName = newValue;
    }

    get lastName(){
        return this._lastName;
    }

    get displayName() {
        return this._firstName + ' '  + this._lastName;
    }

    set lastName(newValue){
        this._lastName = newValue;
    }

    get birthDate(){
        return this._birthDate;
    }

    set birthDate(newValue){
        this._birthDate = newValue;
    }

    get primaryPosition(){
        return this._primaryPosition;
    }

    set primaryPosition(newValue){
        this._primaryPosition = newValue;
    }

    get age(){
        return this._age;
    }

    set age(newValue){
        this._age = newValue;
    }

    get nationality(){
        return this._nationality;
    }

    set nationality(newValue){
        this._nationality = newValue;
    }

    get team(){
        return this._team;
    }

    set team(newValue){
        this._team = newValue;
    }

    get teamId(){
        return this._teamId;
    }

    set teamId(newValue){
        this._teamId = newValue;
    }

    get games(){
        return this._games;
    }

    set games(newValue){
        this._games = newValue;
    }

    get points(){
        return this._points;
    }

    set points(newValue){
        this._points = newValue;
    }

    get goals(){
        return this._goals;
    }

    set goals(newValue){
        this._goals = newValue;
    }

    get assists(){
        return this._assists;
    }

    set assists(newValue){
        this._assists = newValue;
    }   

    get timeOnIcePerGame(){
        return this._timeOnIcePerGame;
    }

    set timeOnIcePerGame(newValue){
        this._timeOnIcePerGame = newValue;
    }

    get shotPct(){
        return this._shotPct;
    }

    set shotPct(newValue){
        this._shotPct = newValue;
    }

    get plusMinus(){
        return this._plusMinus;
    }    

    set plusMinus(newValue){
        this._plusMinus = newValue;
    }

    get pim(){
        return this._pim;
    }

    set pim(newValue){
        this._pim = newValue;
    }

    get hits(){
        return this._hits;
    }
    
    set hits(newValue){
        this._hits = newValue;
    }

    get blocked(){
        return this._blocked;
    }
    
    set blocked(newValue){
        this._blocked = newValue;
    }

    get ppToi(){
        return this._ppToi;
    }
    
    set ppToi(newValue){
        this._ppToi = newValue;
    }

    get shToi(){
        return this._shToi;
    }
    
    set shToi(newValue){
        this._shToi = newValue;
    }

    get pointsPerGame(){
        return this._pointsPerGame;
    }

    set pointsPerGame(newValue){
        this._pointsPerGame = newValue;
    }

    get latestGame(){
        return this._latestGame;
    }

    set latestGame(newValue){
        this._latestGame = newValue;
    }

    get injury(){
        return this._injury;
    }

    set injury(newValue){
        this._injury = newValue;
    }
    
    get espnId(){
        return this._espnId;
    }

    set espnId(newValue){
        this._espnId = newValue;
    }

    get faceoffPct() {
        return this._faceoffPct;
    }

    set faceoffPct(newValue) {
        this._faceoffPct = newValue;
    }    

    get sweaterNumber() {
        return this._sweaterNumber;
    }

    set sweaterNumber(newValue) {
        this._sweaterNumber = newValue;
    }

    get capHit() {
        return this._capHit;
    }

    set capHit(newValue) {
        this._capHit = newValue;
    }

    get capHitNumber() {
        return this._capHitNumber;
    }

    set capHitNumber(newValue) {
        this._capHitNumber = newValue;
    }

    get contract() {
        return this._contract;
    }

    set contract(newValue) {
        this._contract = newValue;
    }
}

export default PlayerModel;