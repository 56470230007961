class GoalieModel {
    get playerId(){
        return this._playerId;
    }

    set playerId(newValue){
        this._playerId = newValue;
    }

    get isActive(){
        return this._isActive;
    }

    set isActive(newValue){
        this._isActive = newValue;
    }
    
    get firstName(){
        return this._firstName;
    }

    set firstName(newValue){
        this._firstName = newValue;
    }

    get lastName(){
        return this._lastName;
    }

    get displayName() {
        return this._firstName + ' '  + this._lastName;
    }

    set lastName(newValue){
        this._lastName = newValue;
    }

    get birthDate(){
        return this._birthDate;
    }

    set birthDate(newValue){
        this._birthDate = newValue;
    }

    get primaryPosition(){
        return this._primaryPosition;
    }

    set primaryPosition(newValue){
        this._primaryPosition = newValue;
    }

    get age(){
        return this._age;
    }

    set age(newValue){
        this._age = newValue;
    }

    get nationality(){
        return this._nationality;
    }

    set nationality(newValue){
        this._nationality = newValue;
    }

    get team(){
        return this._team;
    }

    set team(newValue){
        this._team = newValue;
    }

    get teamId(){
        return this._teamId;
    }

    set teamId(newValue){
        this._teamId = newValue;
    }

    get games(){
        return this._games;
    }

    set games(newValue){
        this._games = newValue;
    }

    get shotsAgainst(){
        return this._shotsAgainst;
    }

    set shotsAgainst(newValue){
        this._shotsAgainst = newValue;
    }

    get goalsAgainst(){
        return this._goalsAgainst;
    }

    set goalsAgainst(newValue){
        this._goalsAgainst = newValue;
    }

    get wins(){
        return this._wins;
    }

    set wins(newValue){
        this._wins = newValue;
    }   

    get timeOnIcePerGame(){
        return this._timeOnIcePerGame;
    }

    set timeOnIcePerGame(newValue){
        this._timeOnIcePerGame = newValue;
    }

    get savePct(){
        return this._savePct;
    }

    set savePct(newValue){
        this._savePct = newValue;
    }

    get losses(){
        return this._losses;
    }

    set losses(newValue){
        this._losses = newValue;
    }

    get ot(){
        return this._ot;
    }

    set ot(newValue){
        this._ot = newValue;
    }

    get latestGame(){
        return this._latestGame;
    }

    set latestGame(newValue){
        this._latestGame = newValue;
    }

    get injury(){
        return this._injury;
    }

    set injury(newValue){
        this._injury = newValue;
    }
    // get (){
    //     return this._;
    // }

    // set (newValue){
    //     this._ = newValue;
    // }
    get sweaterNumber() {
        return this._sweaterNumber;
    }

    set sweaterNumber(newValue) {
        this._sweaterNumber = newValue;
    }

    get capHit() {
        return this._capHit;
    }

    set capHit(newValue) {
        this._capHit = newValue;
    }

    get contract() {
        return this._contract;
    }

    set contract(newValue) {
        this._contract = newValue;
    }
}

export default GoalieModel;