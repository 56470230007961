import Chart, { Ticks } from 'chart.js/auto';
import { Bar } from "react-chartjs-2";
import './PointsPerGameDay.css';
import { useTranslation } from "react-i18next";
import Utilities from '../classes/Utilities';
import SettingStorage from '../classes/SettingsStorage';

const PointsPerGameDay = (props) => {
    const { t } = useTranslation();
    let dates = [];
    let points = [];
    let selectedCountry = SettingStorage.getCountry();

    let currentDayPoints = Utilities.getCountryPointsFromScores(props.data.scores, props.data.players);           
    const currentDate = Utilities.getCurrentRoundDate();
    const currentDateString = `${currentDate.getUTCFullYear()}-${Utilities.pad((currentDate.getUTCMonth() + 1), 2)}-${Utilities.pad(currentDate.getUTCDate(), 2)}`;
    props.data.points[currentDateString] = currentDayPoints;    

    let keys = Object.keys(props.data.points).sort();    
    
    for (let dateId in keys)
    {
        dates.push(new Date(keys[dateId]).toLocaleDateString());
        points.push(props.data.points[keys[dateId]]);
    }

    let amounts = {};    

    for (let p of points)
    {
      if (isNaN(amounts[p])) {
        amounts[p] = 1;
      }
      else {
        amounts[p]++;
      }      
    }

    for (let i=0; i<= Math.max.apply(0, points); i++)
    {
      if (isNaN(amounts[i])) {
        amounts[i] = 0;
      }
    }
        
    Chart.defaults.font.size = 10;    
    const data = {
        labels: dates,
        datasets: [
          {            
            data: points,
            fill: true,
            barPercentage: 1,
            backgroundColor: "#eeeeee",
            borderColor: "#eeeeee",
          }
        ]
      }

    const distributionData = {
        labels: Object.keys(amounts),
        datasets: [
          {            
            data: Object.values(amounts),
            fill: true,
            barPercentage: 1,
            backgroundColor: "#eeeeee",
            borderColor: "#eeeeee",
          }
        ]
    }
    const options = {        
        indexAxis: 'x',
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'right',
          }          
        },
        scales: {   
            y: {                              
                suggestedMin: 0,
                suggestedMax: 22,   
                ticks: {
                    color: ['#bbbbbb']
                }             ,
                grid: {         
                    color: '#393939',           
                    borderColor: '#555555'  
                }
            },
            x: {
                ticks: {
                    color: ['#dddddd']
                },
                grid: {                    
                    borderColor: '#888888'  
                }             
            }            
        }
    };

    return (
        <div>   
            { selectedCountry === "FIN" && <div>Pelipäivän liveseuranta: <a href='https://20p.nhlcountries.com'>20p.nhlcountries.com</a></div>}         
            <h4>{t("pointsPerGameDay_title")}</h4>
            <Bar data={data} options={options}  /> 
            <h4>{t("pointsPerGameDay_distribution")}</h4>
            <Bar data={distributionData} options={options} /> 
        </div>        
    );
}

export default PointsPerGameDay;